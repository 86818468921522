import { gql } from 'apollo-boost'

export default gql`
  query getDashboardData($customerId: ID!, $batchId: ID) {
    files(filters: { customerId: $customerId, batchId: $batchId }) {
      dataFeedsSummary {
        dataFeed
        filesCount
        totalSize
        latestUploadAt
      }
    }
  }
`
