import gql from 'graphql-tag'
import FieldValueData from './FieldValueData.fragment'
import FieldNoteData from './FieldNoteData.fragment'
import FieldStatsData from './FieldStatsData.fragment'

export default gql`
  fragment FieldData on FieldNode {
      id
      name
      completeness
      distinctValues
      isAnomaly
      numericStats {
          ...FieldStatsData
      }
      values {
          ...FieldValueData
      }
      notes {
          ...FieldNoteData
      }
  }
  ${FieldStatsData}
  ${FieldValueData}
  ${FieldNoteData}
`