import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'

import FILE from 'src/store/queries/file.query'

import { FileNode } from 'src/store/all-types'

interface UseFile {
  file: FileNode
  isLoading: boolean
}

export default function useFile({ fileId }): UseFile {
  const { data, loading: isLoading } = useQuery(FILE, { variables: { fileId } })

  const file = useMemo(() => (isLoading ? null : data.file), [data, isLoading])

  return { file, isLoading }
}
