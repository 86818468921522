import { gql } from 'apollo-boost'

import JoinStatusData from './fragments/joinStatuses.fragment'

export default gql`
    query getJoinStatusHistory($joinStatusId: ID!, $fileId: ID!) {
        joinStatusHistory(joinStatusId: $joinStatusId, fileId: $fileId) {
            totalCount
            nodes {
                ...JoinStatusData
            }
        }
    }
    ${JoinStatusData}
`
