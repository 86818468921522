import { gql } from 'apollo-boost'

import batch from './fragments/batch.fragment'

export default gql`
  query getBatchDetails($batchId: Int!) {
    batch(batchId: $batchId) {
      ...batch
    }
  }
  ${batch}
`
