import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'

import JOIN_STATUSES from 'src/store/queries/joinStatuses.query'

export default function useJoinStatuses(sourceFileId = 1) {
  const { data, loading: isLoading } = useQuery(JOIN_STATUSES, { variables: { sourceFileId } })

  const joinStatuses = useMemo(() => (isLoading ? [] : data.joinStatuses.nodes), [data, isLoading])

  return { joinStatuses, isLoading }
}
