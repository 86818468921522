import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'

import JOIN_STATUS_HISTORY from 'src/store/queries/joinStatusHistory.query'

export default function useJoinStatusHistory(joinStatusId, fileId) {
  const { data, loading: isLoading } = useQuery(JOIN_STATUS_HISTORY, { variables: { joinStatusId, fileId } })

  const historyData = useMemo(() => (isLoading ? [] : data.joinStatusHistory.nodes), [data, isLoading])

  return { historyData, isLoading }
}
