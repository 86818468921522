import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'

import FIELDS from 'src/store/queries/fields.query'

import { Field } from '../interfaces'

interface UseFile {
  fields: Field[]
  isLoading: boolean
}

export default function useFile({ fileId }): UseFile {
  const { data, loading: isLoading } = useQuery(FIELDS, { variables: { fileId } })

  const fields = useMemo(() => (isLoading ? [] : data.fields.nodes), [data, isLoading])

  return { fields, isLoading }
}
