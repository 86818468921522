import { gql } from 'apollo-boost'

import fileData from './fragments/file.fragment'

export default gql`
  query getFiles($customerId: ID!, $batchId: ID, $dataFeed: DataFeedEnum) {
    files(filters: { customerId: $customerId, batchId: $batchId, dataFeed: $dataFeed }) {
      nodes {
        ...fileData
      }
    }
  }
  ${fileData}
`
