import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import FILES from 'src/store/queries/files.query'
import { FileNode } from 'src/store/all-types'

interface UseFilesProps {
  customerId?: number
  batchId?: string
  dataFeed?: string
}

interface FilesResponse {
  files: FileNode[]
  isLoading: boolean
}

const defaultProps = { customerId: 1 }

export default function useFiles({ customerId = 1, batchId, dataFeed }: UseFilesProps = defaultProps): FilesResponse {
  const { data, loading: isLoading } = useQuery(FILES, { variables: { customerId, batchId, dataFeed } })

  const files = useMemo(() => (isLoading ? [] : data.files.nodes), [data, isLoading])

  return { files, isLoading }
}
