import { gql } from 'apollo-boost'

import filesStatusSummary from './filesStatusSummary.fragment'

export default gql`
  fragment batch on BatchNode {
    id
    label
    filesCount
    filesStatusSummary {
      ...filesStatusSummary
    }
  }
  ${filesStatusSummary}
`
