import * as React from 'react'

import { Images, Select } from 'nuna-ui-components'

import {Container, Logo, Subheader, LoginContainer, HelpSelectContainer} from './styles'

const Header = () => (
  <Container>
    <Logo><Images.NunaLogoSmall color="white" /></Logo>
    <Subheader>BCBSNC Data Quality Dashboard</Subheader>
    <LoginContainer>
      <HelpSelectContainer>
        <Select placeholderAboveSelect={false} data={['Help', 'Other value']} value="Help" />
      </HelpSelectContainer>
    </LoginContainer>
  </Container>
)

export default Header