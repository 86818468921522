import { gql } from 'apollo-boost'

import JoinStatusData from './fragments/joinStatuses.fragment'

export default gql`
  query getJoinStatuses($sourceFileId: ID!) {
      joinStatuses(sourceFileId: $sourceFileId) {
          totalCount
          nodes {
             ...JoinStatusData
          }
      }
  }
  ${JoinStatusData}
`
