import * as React from 'react'

import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/core'

import extraScopePlugin from 'stylis-plugin-extra-scope'

export const SPECIFICITY_ID = 'extra-specificity'

// because of some wonky stuffy with emotion and #extra-specificity, we use this
// second EmotionProvider in the index.tsx tree to make sure inline css specified
//locally on imported components takes precidence over styles specified in our other libraries.
export const EmotionProvider: React.FC = ({ children }) => (
  <CacheProvider
    value={createCache({
      stylisPlugins: [extraScopePlugin(`#${SPECIFICITY_ID}#${SPECIFICITY_ID}`)],
    })}
  >
    {children}
  </CacheProvider>
)
