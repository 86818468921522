import styled from '@emotion/styled/'

export const Container = styled.div`
  background-color: #234b79;

  padding: 15px 30px;
  position: relative;
  z-index: 1;
`
export const Logo = styled.div`
  color: #fff;
  font-size: 24px;
  width: 220px;
  font-family: Century Gothic;
  line-height: 26px;
`
export const Subheader = styled.div`
  color: #fff;
  font-size: 16px;
  width: 400px;
  font-weight: 500;
  margin-top: 17px;
`

export const LoginContainer = styled.div`
  position: absolute;
  right: 30px;
  color: #fff;
  font-size: 12px;
  top: 15px;
`

export const HelpSelectContainer = styled.div`
  .ant-select-selection {
    width: 78px;
    background: transparent;
    border: none;
    color: #1890ff;
  }

  .ant-select-arrow {
    color: #1890ff;
  }
`
