import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'

import { ReleaseStepNode, FileTypeNode } from 'src/store/all-types'
import RELEASE_FLOW from 'src/store/queries/releaseFlow.query'

export function useReleaseFlowSteps(batchId: string): { releaseFlowSteps: ReleaseStepNode[]; isLoading: boolean } {
  const { data, loading: isLoading } = useQuery(RELEASE_FLOW, { variables: { batchId } })

  const releaseFlowSteps = useMemo(() => (isLoading ? [] : data.releaseFlow.steps), [data, isLoading])

  return { releaseFlowSteps, isLoading }
}

export function useFileTypes(batchId: string): { fileTypes: FileTypeNode[]; isLoading: boolean } {
  const { data, loading: isLoading } = useQuery(RELEASE_FLOW, { variables: { batchId } })

  const fileTypes = useMemo(() => (isLoading ? [] : data.releaseFlow.fileTypes), [data, isLoading])

  return { fileTypes, isLoading }
}
