import { gql } from 'apollo-boost'

import FieldData from './fragments/FieldData.fragment'

export default gql`
  query getFieldsList($fileId: ID!) {
    fields(filters: { fileId: $fileId }) {
      nodes {
        ...FieldData
      }
    }
  }
  ${FieldData}
`
