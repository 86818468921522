import { gql } from 'apollo-boost'

import batch from './batch.fragment'

export default gql`
  fragment fileData on FileNode {
    id
    name
    dataFeed
    createdAt
    updatedAt
    size
    numberOfRecords
    dateFrom
    dateTo
    status
    uniqueFieldsCount
    batch {
      ...batch
    }
    releaseState {
      id
      state
      releaseStep {
        id
        alias
        isActive
        name
      }
      updatedAt
    }
    fileType {
      id
      name
    }
  }
  ${batch}
`
