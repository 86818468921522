import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'

import ALL_BATCHES from 'src/store/queries/allBatches.query'

export default function useAllBatches(customerId = 1) {
  const { data, loading: isLoading } = useQuery(ALL_BATCHES, { variables: { customerId } })

  const batchesList = useMemo(() => (isLoading ? [] : data.batches.nodes), [data, isLoading])

  return { batchesList, isLoading }
}
