import { gql } from 'apollo-boost'
import FieldData from './fragments/FieldData.fragment'

export default gql`
  query getFieldHistory($fieldId: ID!, $fileStatus: FileStatusEnum) {
      fieldHistory(filters: { fieldId: $fieldId, fileStatus: $fileStatus }) {
          totalCount
          nodes {
              ...FieldData
          }
      }
  }
  ${FieldData}
`
