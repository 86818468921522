import { gql } from 'apollo-boost'

export default gql`
  query getBatchReleaseFlow($batchId: ID!) {
    releaseFlow(batchId: $batchId) {
      steps {
        id
        isActive
        name
        alias
      }
      fileTypes {
        id
        name
      }
    }
  }
`
