import { gql } from 'apollo-boost'

import batch from './fragments/batch.fragment'

export default gql`
  query getAllBatches($customerId: ID!) {
    batches(filters: { customerId: $customerId }) {
      nodes {
        ...batch
      }
    }
  }
  ${batch}
`
