import React, { useEffect, createContext, FC, useState } from 'react'
import { useAllBatches } from 'src/store/hooks'

type Release = {
  id: number
  label: string
  filesCount: number
}

interface ReleaseContextInterface {
  isLoading: boolean
  list: Release[]
  activeRelease: Release
  selectRelease(id: string): boolean
}

export const ReleaseContext = createContext<ReleaseContextInterface>({
  list: [],
  isLoading: true,
  activeRelease: null,
  selectRelease: () => false,
})

export const ReleaseProvider: FC = ({ children }) => {
  const { isLoading, batchesList } = useAllBatches()
  const [activeRelease, setActiveRelease] = useState({
    id: null,
    label: null,
    filesCount: 0,
  })

  useEffect(() => {
    if (!isLoading && batchesList && batchesList.length) {
      setActiveRelease(batchesList[0])
    }
  }, [batchesList, isLoading])

  const selectRelease = (id: string) => {
    const release = batchesList.find((item: { id: string }) => item.id === id)
    if (release) {
      setActiveRelease(release)
      return true
    }
    return false
  }

  return (
    <ReleaseContext.Provider
      value={{
        isLoading,
        list: batchesList,
        activeRelease,
        selectRelease,
      }}
    >
      {children}
    </ReleaseContext.Provider>
  )
}
