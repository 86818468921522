import React, { lazy, Suspense } from 'react'
import { QueryParamProvider } from 'use-query-params'
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import { ReleaseProvider } from 'src/pages/RootPage/ReleaseProvider'
import {
  EmotionProvider as ComponentsEmotionProvider,
  GenericErrorBoundary,
  NunaThemeProvider,
  StubbableFetchProvider,
} from 'nuna-ui-components'
import { EmotionProvider } from 'src/util/EmotionProvider'
import { CONFIG } from 'src/data/config'

import Header from 'src/components/Header'
import LoadingIndicator from 'src/components/LoadingIndicator'

const SelectLatestRelease = lazy(() => import('src/pages/RootPage/SelectLatestRelease'))
const FeedDetailsView = lazy(() => import('src/pages/FeedDetails/FeedDetails'))
const FileDetailsView = lazy(() => import('./pages/FileDetails/FileDetails'))
const FileStatusView = lazy(() => import('./pages/FileStatus/FileStatusView'))
const PageNotFoundView = lazy(() => import('src/pages/PageNotFound'))

const client = new ApolloClient({
  uri: CONFIG.GRAPHQL_API_URL,
})

const App = () => (
  <Router>
    <GenericErrorBoundary>
      <StubbableFetchProvider stubs={[]}>
        <ComponentsEmotionProvider>
          <NunaThemeProvider>
            <EmotionProvider>
              <ApolloProvider client={client}>
                <ReleaseProvider>
                  <Header />
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Suspense fallback={<LoadingIndicator />}>
                      <Switch>
                        <Route exact component={SelectLatestRelease} path="/" />
                        <Route exact component={FileStatusView} path="/releases/:batchId" />
                        <Route exact component={FeedDetailsView} path="/feeds/:feedId" />
                        <Route exact component={FileDetailsView} path="/releases/:batchId/files/:fileId/:tab?" />
                        <Route exact component={PageNotFoundView} path="/page-not-found" />
                        <Redirect to="/page-not-found" />
                      </Switch>
                    </Suspense>
                  </QueryParamProvider>
                </ReleaseProvider>
              </ApolloProvider>
            </EmotionProvider>
          </NunaThemeProvider>
        </ComponentsEmotionProvider>
      </StubbableFetchProvider>
    </GenericErrorBoundary>
  </Router>
)

export default App
