import gql from 'graphql-tag'
// Do not use fileData fragment as we need only small amount of fields
//import fileData from './file.fragment'

export default gql`
    fragment JoinStatusData on JoinStatusNode {
        id,
        sourceKey
        targetKey
        source {
            id
            name
            fileType {
                id
                name
            }
        }
        target {
            id
            name
            fileType {
                id
                name
            }
        }
        rate
        rateType
        rateNumerator
        rateDenominator
        isExpectedValue
        sourceCardinality
        targetCardinality
    }
`