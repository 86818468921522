import { gql } from 'apollo-boost'

import fileData from './fragments/file.fragment'

export default gql`
  query getFile($fileId: ID!) {
    file(fileId: $fileId) {
      ...fileData
    }
  }
  ${fileData}
`
