import React from 'react'
import styled from '@emotion/styled'
import { Spin } from 'nuna-ui-components'

const LoadingContainer = styled.div`
  text-align: center;
  padding: 40px 20px;
  font-size: 18px;
`
type LoadingIndicatorProps = {
  size?: number
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ size = 24 }) => {
  return (
    <LoadingContainer>
      <Spin fontSize={size} />
      &nbsp; &nbsp; Loading...
    </LoadingContainer>
  )
}

export default LoadingIndicator
